import React from 'react';
// import Hamburgesa from './Components/Hamburgesa';

import {BrowserRouter as Router} from "react-router-dom";
import Routes from "./routes/Routes";

const App = () => {
  return (
    <Router>
      <>

    {/* <Header /> */}
    {/* <Hamburgesa/> */}
    <Routes />
      </>
    </Router>
  );
}

export default App;
